import React, { useRef, useEffect } from "react";

const Dropdown = ({ isOpen, toggle, items, isLoading }) => {
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                toggle(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [toggle]);

    const handleItemClick = (item) => {
        item.onClick();
        toggle(false);
    };

    return (
        <div className="dropdown" ref={dropdownRef}>
            <div className={`button icon ${isLoading ? 'disabled' : ''}`} onClick={() => !isLoading && toggle(!isOpen)}>
                {isLoading ? (
                    <i className="fa-solid fa-spinner fa-spin"></i>
                ) : (
                    <i className="fa-solid fa-hat-wizard"></i>
                )}
            </div>
            {isOpen && (
                <div className="dropdown-content">
                    {items.map((item, index) => (
                        <div key={index} onClick={() => handleItemClick(item)}>
                            {item.text}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Dropdown;