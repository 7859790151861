import React from "react";
import "../assets/landing.css";

const Landing = () => {
    const currentYear = new Date().getUTCFullYear();

    return (
        <div className="container">
            <img className="j-logotype mb-2" src="/jotting_logo.svg" alt="jotting logo" />
            <div className="hero mb-2">
                Add notes<br />to <span className="accent">any</span> video<br />easily
            </div>
            <form action="/api/videos" method="POST">
                <div className="input-container mb-1">
                    <input 
                        className="input-field" 
                        type="text" 
                        name="source" 
                        placeholder="e.g. https://www.youtube.com/watch?v=0lJKucu6HJc" 
                    />
                    <button className="kb spacebar" type="submit">Enter</button>
                </div>
            </form>
            <div className="helper">Add YouTube <span className="accent">link</span> to start jotting</div>
            <footer>&copy; jotting.red {currentYear}</footer>
        </div>
    );
};

export default Landing;