import React from "react";

const STYLE = {
    height: '300px',
};

const HINTS = [
    { keyText: ['N'], description: 'Add new note at current time position', bgColor: 'danger' },
    { keyText: ['Space'], description: 'Play/Pause', bgColor: 'dark' },
    { keyText: ['Right'], description: 'Forward 5 seconds', bgColor: 'dark' },
    { keyText: ['Left'], description: 'Backward 5 seconds', bgColor: 'dark' },
    { keyText: ['1', '2', '3', '4'], description: 'Change playback speed (x1, x1.5, x1.75, x2)', bgColor: 'dark' },
];

const Hint = ({ keyText, description, bgColor }) => (
    <div className="kb-set">
        {keyText.map((key, index) => (
            <span key={index} className={`kb ${key.length > 1 ? 'long' : ''} ${bgColor}`}>{key}</span>
        ))}
        <span className="kb_description">{description}</span>
    </div>
);

const KeyboardShortcuts = () => (
    <div className="keyboard-shortcuts">
        {HINTS.map((hint, index) => (
            <Hint key={index} {...hint} />
        ))}
    </div>
);

const KeyboardShortcutsContainer = () => (
    <div id="keyboard-shortcuts-container" style={STYLE}>
        <KeyboardShortcuts />
    </div>
);

export default KeyboardShortcutsContainer;