import React, { useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";

import { getCommandKey } from "../utils/tools";
import { ACTIONS } from "../reducers/videoReducer";
import API from "../utils/api";
import { secondsToTime } from "../utils/tools";
import "../assets/components.css";

// Styles moved to separate objects for better organization and reusability
const MODAL_STYLE = {
    display: 'flex',
    flexDirection: 'column',
    gap: '.5rem',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    background: '#020617',
    padding: '1rem',
    borderRadius: '.5rem',
    boxShadow: `0px 25px 50px -12px rgba(0, 0, 0, 0.24),
                0px 25px 250px 240px rgba(0, 0, 0, 0.20)`
};

const TEXT_AREA_STYLE = {
    boxSizing: 'border-box',
    width: '240px',
    height: '240px',
    border: 'none',
    borderRadius: '.25rem',
    fontFamily: `'Lato', sans-serif`,
    fontSize: '1rem',
    padding: '1rem 1.5rem',
    background: '#0f172a',
    color: '#cbd5e1'
};

const TIME_STYLE = {
    fontSize: '0.75rem',
    color: '#f27a93',
};

const Modal = ({ isOpen, timePosition, text, dispatch, video, keyPressed }) => {
    const [note, setNote] = useState('');

    // Use useCallback to memoize the addNote function
    const addNote = useCallback(async () => {
        if (!note || !note.trim()) return;

        dispatch({ type: ACTIONS.PLAY });
        dispatch({ type: ACTIONS.TOGGLE_MODAL });

        const body = { text: note.trim(), timePosition: timePosition, source: video._id['$oid'] };
        
        // API.post('/api/notes', body)
        //     .then(data => dispatch({ type: ACTIONS.SET_NOTES, payload: { notes: data } }))
        //     .catch(error => console.error("Error adding note:", error));
        try {
            const response = await API.post(`/api/notes`, body);
            dispatch({ type: ACTIONS.SET_NOTES, payload: { notes: response.data } });
        } catch (error) {
            console.error("Error adding note:", error);
        }

        setNote('');
    }, [note, timePosition, video, dispatch]);

    useEffect(() => {
        setNote(text || '');
    }, [text]);

    // Move hotkey logic to a separate useEffect for better separation of concerns
    useEffect(() => {
        if (!isOpen) return;

        const handleHotkey = (e) => {
            if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
                addNote();
            }
            if (e.keyCode === 27) { // ESC key
                dispatch({ type: ACTIONS.PLAY });
                dispatch({ type: ACTIONS.TOGGLE_MODAL });
                setNote('');
            }
        };

        window.addEventListener('keydown', handleHotkey);
        return () => window.removeEventListener('keydown', handleHotkey);
    }, [isOpen, addNote, dispatch]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className="modal" id="modal" style={MODAL_STYLE}>
            <div style={TIME_STYLE}>{secondsToTime(timePosition)}</div>
            <div>
                <textarea
                    value={note}
                    onChange={event => setNote(event.target.value)}
                    autoFocus
                    id='note'
                    style={TEXT_AREA_STYLE}
                    placeholder='Type your note...'
                />
            </div>
            <div style={{textAlign: 'right', width: '100%'}}>
                <div className="kb long" onClick={addNote}>{getCommandKey()} + Enter</div>
            </div>
        </div>,
        document.getElementById('modal-portal')
    );
};

export default Modal;