import React, { useEffect, useCallback } from 'react';
import { ACTIONS } from '../reducers/videoReducer';

// Move player variable inside the component to avoid global state
const YoutubePlayer = ({ video, action, seekTo, dispatch }) => {
    // Use useRef to store the player instance
    const playerRef = React.useRef(null);

    // Helper function to get the current player instance
    const getPlayer = () => playerRef.current;

    // Memoize the timePosition function to avoid unnecessary re-creations
    const timePosition = useCallback((modifier) => {
        const player = getPlayer();
        return player ? Math.round(player.getCurrentTime()) + modifier : 0;
    }, []);

    // Extract video ID parsing logic into a separate function
    const getVideoId = useCallback((source) => {
        const regExp = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        const [, videoID] = source.match(regExp);
        return videoID;
    }, []);

    // Create a memoized onYouTubeIframeAPIReady function
    const onYouTubeIframeAPIReady = useCallback(() => {
        playerRef.current = new window.YT.Player('youtube_player', {
            height: '100%',
            width: '100%',
            videoId: getVideoId(video.source),
            playerVars: {
                'autoplay': 1,
                'controls': 1,
                'fs': 0,
                'rel': 1,
                'start': 0,
                'enablejsapi': 1,
            },
            events: {
                'onReady': () => {
                    // You can add any onReady logic here if needed
                },
                'onStateChange': (event) => {
                    document.getElementById("youtube_player").blur();
                    window.focus();
                }
            }
        });
    }, [video, getVideoId]);

    // Effect to load YouTube IFrame API
    useEffect(() => {
        if (!window.YT) {
            const tag = document.createElement('script');
            tag.src = 'https://www.youtube.com/iframe_api';
            window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        } else {
            onYouTubeIframeAPIReady();
        }
    }, [onYouTubeIframeAPIReady]);

    // Effect to change video
    useEffect(() => {
        const player = getPlayer();
        if (player) {
            player.loadVideoById(getVideoId(video.source), 0);
        }
    }, [video, getVideoId]);

    // Effect to handle player actions
    useEffect(() => {
        if (!action) return;

        const player = getPlayer();
        if (!player) return;

        const actions = {
            [ACTIONS.PLAY]: () => player.playVideo(),
            [ACTIONS.PAUSE_AND_GET_TIME]: () => {
                player.pauseVideo();
                dispatch({type: ACTIONS.SET_TIME_POSITION, payload: { timePosition: Math.round(player.getCurrentTime()) }});
            },
            [ACTIONS.PAUSE]: () => player.pauseVideo(),
            [ACTIONS.PLAYPAUSE]: () => {
                if (player.getPlayerState() === 1) {
                    player.pauseVideo();
                    dispatch({type: ACTIONS.SET_TIME_POSITION, payload: { timePosition: Math.round(player.getCurrentTime()) }});
                } else {
                    player.playVideo();
                }
            },
            [ACTIONS.BACKWARD]: () => player.seekTo(timePosition(-5), true),
            [ACTIONS.FORWARD]: () => player.seekTo(timePosition(5), true),
            [ACTIONS.SPEED + '1']: () => player.setPlaybackRate(1),
            [ACTIONS.SPEED + '2']: () => player.setPlaybackRate(1.5),
            [ACTIONS.SPEED + '3']: () => player.setPlaybackRate(1.75),
            [ACTIONS.SPEED + '4']: () => player.setPlaybackRate(2),
            [ACTIONS.SEEK_TO]: () => {
                if (seekTo !== null) {
                    player.seekTo(seekTo);
                    player.playVideo();
                }
            },
        };

        const actionFunction = actions[action];
        if (actionFunction) {
            actionFunction();
        }

        dispatch({ type: 'reset' });
    }, [action, dispatch, seekTo, timePosition]);

    return <div id="youtube_player"></div>;
};

export default YoutubePlayer;