import React from "react";
import { Routes, Route } from "react-router-dom";

import Landing from './pages/Landing';
import Share from './pages/Share';
import Video from './pages/Video';

const App = () => {
    return (
        <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="video/:id" element={<Video />} />
            <Route path="share/:hash" element={<Share />} />
        </Routes>
    );
};

export default App;
