export function secondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    let time;
  
    hours > 0
    ? time = `${hours}:${formattedMinutes}:${formattedSeconds}`
    : time = `${formattedMinutes}:${formattedSeconds}`

    return time;
}

export function getCommandKey() {
    if (navigator.userAgent.includes('Macintosh')) {
        return '⌘';
    } else {
        return 'Ctrl';
    }
}