import React, { useEffect, useCallback } from "react";
import "../assets/notes_list.css";
import API from "../utils/api";
import { ACTIONS } from "../reducers/videoReducer";
import { secondsToTime } from "../utils/tools";

const Notes = ({ video, notes, notifications, dispatch }) => {
    const notificationTypes = {
        'error': '😞',
        'warning': '🦄',
    };

    useEffect(() => {
        if (!video) return;
        
        const fetchNotes = async () => {
            try {
                const response = await API.get(`/api/notes?source=${video._id['$oid']}`);
                dispatch({ type: ACTIONS.SET_NOTES, payload: { notes: response.data } });
            } catch (error) {
                console.error("Error fetching notes:", error);
            }
        };

        fetchNotes();
    }, [video, dispatch]);

    const editNote = useCallback((event, note) => {
        event.stopPropagation();
        dispatch({ type: ACTIONS.PAUSE });
        dispatch({ type: ACTIONS.SET_TIME_POSITION, payload: { timePosition: note.timePosition } });
        dispatch({ type: ACTIONS.OPEN_MODAL, payload: { noteText: note.text } });
    }, [dispatch]);

    const deleteNote = useCallback(async (event, id) => {
        event.stopPropagation();
        try {
            const response = await API.delete(`/api/notes/${id}`);
            dispatch({ type: ACTIONS.SET_NOTES, payload: { notes: response.data } });
        } catch (error) {
            console.error("Error deleting note:", error);
        }
    }, [dispatch]);

    const closeNotification = useCallback((key) => {
        dispatch({ type: ACTIONS.DELETE_NOTIFICATION, payload: { key } });
    }, [dispatch]);

    const seekTo = useCallback((timePosition) => {
        dispatch({ type: ACTIONS.SEEK_TO, payload: { seekTo: timePosition } });
    }, [dispatch]);

    return (
        <>
            {notifications.map((notification, key) => (
                <div className="notification" key={key}>
                    <div>{notificationTypes[notification.type]}</div>
                    <div dangerouslySetInnerHTML={{ __html: notification.text }} />
                    <div onClick={() => closeNotification(key)}><i className="fa-solid fa-xmark"></i></div>
                </div>
            ))}
            {notes.map((note) => (
                <div className="note" key={note._id['$oid']} onClick={() => seekTo(note.timePosition)}>
                    <div>
                        <div>
                            <div>
                                <div className="time_code">{secondsToTime(note.timePosition)}</div>
                            </div>
                            <div>{note.text}</div>
                            <div className="actions">
                                <div onClick={(event) => editNote(event, note)}><i className="fa-solid fa-pen fa-xs"></i></div>
                                <div onClick={(event) => deleteNote(event, note._id['$oid'])}><i className="fa-solid fa-trash-can fa-xs"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default Notes;
