// Initial state object
export const initialState = {
    playerAction: null,
    modalIsOpen: false,
    timePosition: null,
    seekTo: null,
    notes: [],
    noteText: null,
    notifications: [],
}

// Action types as constants
export const ACTIONS = {
    PLAYPAUSE: 'playPause',
    PLAY: 'play',
    PAUSE: 'pause',
    PAUSE_AND_GET_TIME: 'pauseAndGetTime',
    SPEED: 'speed',
    FORWARD: 'forward',
    BACKWARD: 'backward',
    RESET: 'reset',
    TOGGLE_MODAL: 'toggleModal',
    OPEN_MODAL: 'openModal',
    SET_TIME_POSITION: 'setTimePosition',
    SEEK_TO: 'seekTo',
    SET_NOTES: 'setNotes',
    ADD_NOTIFICATION: 'addNotification',
    DELETE_NOTIFICATION: 'deleteNotification',
}

// Helper functions for common state updates
const updatePlayerAction = (state, action) => ({ ...state, playerAction: action });
const updateModalState = (state, isOpen, noteText = null) => ({ ...state, modalIsOpen: isOpen, noteText });

// Main reducer function
function reducer(state, action) {
    switch (action.type) {
        case ACTIONS.PLAYPAUSE:
        case ACTIONS.PLAY:
        case ACTIONS.PAUSE:
        case ACTIONS.PAUSE_AND_GET_TIME:
        case ACTIONS.BACKWARD:
        case ACTIONS.FORWARD:
            return updatePlayerAction(state, action.type);
        case ACTIONS.SPEED:
            return updatePlayerAction(state, `${ACTIONS.SPEED}${action.payload.rate}`);
        case ACTIONS.SET_TIME_POSITION:
            return { ...state, timePosition: action.payload.timePosition };
        case ACTIONS.RESET:
            return { ...state, playerAction: null, seekTo: null };
        case ACTIONS.TOGGLE_MODAL:
            return updateModalState(state, !state.modalIsOpen);
        case ACTIONS.OPEN_MODAL:
            return updateModalState(state, true, action.payload.noteText);
        case ACTIONS.SET_NOTES:
            return { ...state, notes: action.payload.notes };
        case ACTIONS.SEEK_TO:
            return { ...state, playerAction: ACTIONS.SEEK_TO, seekTo: action.payload.seekTo };
        case ACTIONS.ADD_NOTIFICATION:
            return { ...state, notifications: [action.payload.notification, ...state.notifications] };
        case ACTIONS.DELETE_NOTIFICATION:
            return { ...state, notifications: state.notifications.filter((_, index) => index !== action.payload.key) };
        default:
            return state;
    }
}

export default reducer;