import React, { useEffect, useState, createContext, useReducer, useCallback } from "react";
import { useParams } from "react-router-dom";

import "../assets/layout.css";
import "../assets/components.css";
import YoutubePlayer from "../components/youtubePlayer";
import Modal from "../components/modal";
import Notes from "../components/notes";
import KeyboardShortcutsContainer from "../components/keyboardShortcuts";
import reducer, { initialState, ACTIONS } from "../reducers/videoReducer";
import { LINKS } from "../constants";
import API from "../utils/api";
import Dropdown from "../components/dropdown";
// Create a context for sharing video-related state across components
export const VideoContext = createContext(null);

const Video = () => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const { id } = useParams();
    const [video, setVideo] = useState(null);
    const [keyPressed, setKeyPressed] = useState(false);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);

    // Notifications object moved outside of component for better performance
    const notifications = {
        404: { type: 'error', text: 'Sorry, for now, we\'re supporting videos up to 10 minutes for AI-generated notes.' },
        401: { type: 'warning', text: `Please <a href='\\login?next=video\\${id}'>Login</a> to start using the AI feature.` },
    };

    // Keyboard event handlers
    const downHandler = useCallback((e) => setKeyPressed(e), []);
    const upHandler = useCallback(() => setKeyPressed(false), []);

    // Generate notes using AI
    const generateNotes = useCallback(async () => {
        setIsGeneratingNotes(true);
        try {
            const response = await API.get(`/api/ai/${video._id['$oid']}`);
            if (response.status === 200) {
                dispatch({ type: ACTIONS.SET_NOTES, payload: { notes: response.data } });
            } else {
                throw new Error(response.status);
            }
        } catch (error) {
            console.log(error);
            const errorStatus = error.message || 500;
            const errorNotification = notifications[errorStatus] || { type: 'error', text: 'An error occurred while generating notes.' };
            dispatch({ type: ACTIONS.ADD_NOTIFICATION, payload: { notification: errorNotification } });
        } finally {
            setIsGeneratingNotes(false);
        }
    }, [video, notifications]);

    // Fetch video data
    useEffect(() => {
        const fetchVideo = async () => {
            try {
                const response = await API.get(`/api/get_video/${id}`);
                setVideo(response.data);
            } catch (error) {
                console.error("Error fetching video:", error);
            }
        };
        fetchVideo();
    }, [id]);

    // Set up keyboard event listeners
    useEffect(() => {
        window.addEventListener("keydown", downHandler);
        window.addEventListener("keyup", upHandler);
        return () => {
            window.removeEventListener("keydown", downHandler);
            window.removeEventListener("keyup", upHandler);
        };
    }, [downHandler, upHandler]);

    // Handle hotkeys
    useEffect(() => {
        if (state.modalIsOpen) return;

        const hotkeyActions = {
            78: () => {
                dispatch({ type: ACTIONS.TOGGLE_MODAL });
                dispatch({ type: ACTIONS.PAUSE_AND_GET_TIME });
            },
            32: () => dispatch({ type: ACTIONS.PLAYPAUSE }),
            39: () => dispatch({ type: ACTIONS.FORWARD }),
            37: () => dispatch({ type: ACTIONS.BACKWARD }),
            49: () => dispatch({ type: ACTIONS.SPEED, payload: { rate: '1' } }),
            50: () => dispatch({ type: ACTIONS.SPEED, payload: { rate: '1.5' } }),
            51: () => dispatch({ type: ACTIONS.SPEED, payload: { rate: '1.75' } }),
            52: () => dispatch({ type: ACTIONS.SPEED, payload: { rate: '2' } }),
        };

        const action = hotkeyActions[keyPressed.keyCode];
        if (action) action();
    }, [keyPressed, state.modalIsOpen]);

    const toggleDropdown = (isOpen) => {
        setDropdownOpen(isOpen);
    };

    return (
        <VideoContext.Provider value={{ state, dispatch }}>
            <div id="interface">
                <div id="left-side">
                    {video && video.type === 'youtube' && (
                        <div id="video_player" className="youtube-container">
                            <YoutubePlayer
                                video={video}
                                action={state.playerAction}
                                seekTo={state.seekTo}
                                dispatch={dispatch}
                            />
                        </div>
                    )}
                    <KeyboardShortcutsContainer />
                </div>
                <div id="right-side">
                    <div id="header">
                        <img src="/jotting_logo.svg" alt="jotting logo" style={{width: "80px"}} />
                        <div className="actions">
                            <Dropdown
                                isOpen={dropdownOpen}
                                toggle={toggleDropdown}
                                items={[
                                    { text: "Generate Notes", onClick: generateNotes },
                                    // { text: "Summary", onClick: generateNotes },
                                    // Add more dropdown items here
                                ]}
                                isLoading={isGeneratingNotes}
                            />
                            <div className="button icon" href={LINKS.HOME} target="_blank" rel="noopener noreferrer"><i className="fa-solid fa-plus"></i></div>
                        </div>
                    </div>
                    <Notes
                        video={video}
                        notes={state.notes}
                        notifications={state.notifications}
                        dispatch={dispatch}
                    />
                </div>
                <Modal
                    isOpen={state.modalIsOpen}
                    timePosition={state.timePosition}
                    text={state.noteText}
                    video={video}
                    dispatch={dispatch}
                    keyPressed={keyPressed}
                />
            </div>
        </VideoContext.Provider>
    );
};

export default Video;
