// Base configuration for fetch requests
const baseConfig = {
    headers: {
        'Content-Type': 'application/json',
    },
};

// Helper function to handle API responses
const handleResponse = async (response) => {
    const data = await response.json();
    return {
        status: response.status,
        data: data
    };
};

const API = {
    // GET request
    get: async (endPoint) => {
        const response = await fetch(endPoint, {
            ...baseConfig,
            method: 'GET',
        });
        return handleResponse(response);
    },

    // POST request
    post: async (endPoint, body) => {
        const response = await fetch(endPoint, {
            ...baseConfig,
            method: 'POST',
            body: JSON.stringify(body),
        });
        return handleResponse(response);
    },

    // DELETE request
    delete: async (endPoint, body) => {
        const response = await fetch(endPoint, {
            ...baseConfig,
            method: 'DELETE',
            body: JSON.stringify(body),
        });
        return handleResponse(response);
    }
};

export default API;

// Explanation of refactoring:
// 1. Created a baseConfig object to store common configuration (headers).
// 2. Introduced a handleResponse helper function to centralize response handling logic.
// 3. Simplified each method (get, post, delete) by using the spread operator to include baseConfig.
// 4. Consistent use of handleResponse across all methods for uniform error handling and response parsing.
// 5. Removed redundant async/await in the return statement of post and delete methods.
// This refactoring improves code reusability, readability, and maintainability.